import { HalfPageLoadingSpinner } from 'components/basics/LoadingSpinner';
import Grid from 'components/profile/Grid';
import { useGetClubsByProfileIdQuery } from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useRouter } from 'next/router';
import { Waypoint } from 'react-waypoint';
import { EmptyState } from 'ui/generic';
import { ClubInline } from 'ui/specific/inline/ClubInline';
import { QS } from 'utils/queryStringParams';
import { routes } from 'utils/routes';

type Props = {
  profileId: string;
  profileName: string;
  isMyProfile: boolean;
};

const ClubTab = ({ profileId, profileName, isMyProfile }: Props): JSX.Element | null => {
  const router = useRouter();
  const { setCreateClubWizard } = useGlobalModalContext();
  const {
    data: clubs,
    loading,
    fetchMore,
  } = useGetClubsByProfileIdQuery({
    variables: { profileId, limit: 4, offset: 0 },
  });

  if (!clubs && loading) return <HalfPageLoadingSpinner />;
  if (!clubs) return null;

  if (clubs.getClubsByProfileId.length === 0)
    return (
      <EmptyState
        text={isMyProfile ? 'Not part of any clubs yet' : `${profileName} is not part \n of any clubs yet`}
        illustrationKey="angry-dude"
        actions={
          isMyProfile
            ? [
                {
                  text: 'Start a club',
                  onClick: () => setCreateClubWizard(true),
                },
                {
                  text: 'Find a club',
                  onClick: () => router.push(`${routes.clubs}?${QS.clubSection}=exploreClubs`),
                },
              ]
            : undefined
        }
        margin={{ '0': '5', '1120': '5 0' }}
      />
    );

  return (
    <div className="mt-5">
      <Grid>
        {clubs.getClubsByProfileId.map((club) => (
          <ClubInline key={club.id} club={club} link border />
        ))}
      </Grid>
      <Waypoint
        onEnter={() => {
          if (!clubs || loading) return;
          fetchMore({
            variables: {
              offset: clubs.getClubsByProfileId.length,
              limit: 4,
            },
          });
        }}
      />
    </div>
  );
};

export default ClubTab;
