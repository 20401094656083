import { ProfilePartsFragment } from 'generated/graphql';
import getConfig from 'next/config';
import Head from 'next/head';
import { getSharableProfileImageUrl } from 'utils/shareMedia';

const { publicRuntimeConfig } = getConfig();

type Props = {
  profile: ProfilePartsFragment;
};

export const ProfilePageMetadata = ({ profile }: Props): JSX.Element => {
  const shareImage = getSharableProfileImageUrl(profile.handle);
  const ogTitle = `${profile.name}'s Literal profile`;

  return (
    <Head>
      <title key="title">
        {profile.name} / {profile.handle} | Literal
      </title>
      <meta
        name="description"
        content={`${profile.bio}. See what they’re currently reading and their full library on Literal.`}
      />

      <link rel="canonical" href={`${publicRuntimeConfig.urlBase}/${profile.handle}`} />
      <meta name="description" property="og:description" key="ogdescription" content={profile.bio || ''} />
      <meta property="og:url" key="ogurl" content={`${publicRuntimeConfig.urlBase}/${profile.handle}`} />
      <meta property="og:image" key="ogimage" content={shareImage} />
      <meta property="og:title" key="ogtitle" content={ogTitle} />
      <meta property="twitter:title" key="twittertitle" content={ogTitle} />
      <meta property="twitter:image" key="twitterimage" content={shareImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:image:height" key="ogimageheight" content="800" />
      <meta property="og:image:width" key="ogimagewidth" content="1280" />
    </Head>
  );
};
