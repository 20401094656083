import { ReactNode } from 'react';
import styles from './Grid.module.scss';

type Props = {
  children: ReactNode;
};

const Grid = ({ children }: Props): JSX.Element => {
  return <div className={styles.grid}>{children}</div>;
};

export default Grid;
