import { LinkSimple, SectionHeader } from 'ui/generic';
import styles from './ListHeader.module.scss';

type Props = {
  title: string;
  listSlug: string;
  booksCount: number;
  ownerHandle: string;
  hideViewMore?: boolean;
};

export const ListHeader = ({
  title,
  listSlug,
  booksCount,
  ownerHandle,
  hideViewMore,
}: Props): JSX.Element => {
  return (
    <SectionHeader
      title={title}
      number={
        <LinkSimple
          className={styles.bookCount}
          href={`/[handle]/${listSlug}`}
          as={`/${ownerHandle}/${listSlug}`}
          variant="faded"
        >
          {`${booksCount} book${booksCount > 1 || booksCount === 0 ? 's' : ''}`}
        </LinkSimple>
      }
      right={
        !hideViewMore &&
        booksCount > 1 && (
          <LinkSimple
            className={`nav-link ${styles.viewAll}`}
            href={`/[handle]/${listSlug}`}
            as={`/${ownerHandle}/${listSlug}`}
            variant="faded"
          >
            View all
          </LinkSimple>
        )
      }
    />
  );
};
