import { default as classNames, default as classnames } from 'classnames';
import { BrandMark } from 'components/layout/shared/Brand';
import { BookPartsFragment, ProfilePartsFragment } from 'generated/graphql';
import { ShareFormat, useGetShareFormat } from 'hooks/useGetShareFormat';
import { useMemo } from 'react';
import { Avatar, Cover } from 'ui/generic';
import { chunkArray } from 'utils/array';
import styles from './ProfileShare.module.scss';

type Props = {
  profile: ProfilePartsFragment;
  books: BookPartsFragment[];
  shareFormat?: ShareFormat;
};

export const ProfileShare = ({ profile, books, shareFormat }: Props): JSX.Element => {
  const sharingFormat = useGetShareFormat(shareFormat);
  const chunked = useMemo(() => chunkArray<BookPartsFragment>(books, 5), [books]);
  const avatarWidth = useMemo(() => {
    if (sharingFormat === 'landscape') return 104;
    if (sharingFormat === 'square') {
      return 235;
    }
    return 405;
  }, [sharingFormat]);

  return (
    <div
      className={classnames(styles.container, {
        [styles.square]: sharingFormat === 'square',
        [styles.portrait]: sharingFormat === 'portrait',
      })}
    >
      <div className={styles.top}>
        <div className={styles.logo}>
          <BrandMark />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.profile}>
          <div className={styles.avatar}>
            <Avatar profile={profile} width={avatarWidth} />
          </div>
          <div className={classNames('uiObjectTitleFocus', 'mt-5', styles.title)}>{profile.name}</div>
          <p className={styles.faded}>@{profile.handle} on Literal</p>
        </div>
      </div>
      <div className={styles.books}>
        {sharingFormat === 'landscape' &&
          books.slice(0, 6).map((book) => (
            <div key={book.id} className={styles.bookHolder}>
              <Cover book={book} assetSize="300" />
            </div>
          ))}
        {sharingFormat === 'square' &&
          books.slice(0, 4).map((book) => (
            <div key={book.id} className={styles.bookHolder}>
              <Cover book={book} assetSize="300" />
            </div>
          ))}

        {sharingFormat === 'portrait' && (
          <>
            {chunked.map((column, i) => (
              <div key={`${i}`} className={styles.column}>
                {column.map((book) => (
                  <div key={book.id} className={styles.bookHolderPortrait}>
                    {book.cover ? (
                      <img src={book.cover} alt={book.title} />
                    ) : (
                      <Cover book={book} assetSize="300" />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
