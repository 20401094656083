import classNames from 'classnames';
import { HalfPageLoadingSpinner } from 'components/basics/LoadingSpinner';
import GoalMini from 'components/goal/GoalMini';
import { dayjs } from 'date-utils';
import { ProfilePartsFragment, useGoalParticipationsByHandleQuery } from 'generated/graphql';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { EmptyState } from 'ui/generic';
import { routes } from 'utils/routes';
import styles from './ReviewsTab.module.scss';

type Props = {
  profile: ProfilePartsFragment;
  isMyProfile: boolean;
};

export const GoalsTab = ({ profile, isMyProfile }: Props): JSX.Element => {
  const router = useRouter();
  const today = useMemo(() => dayjs().endOf('day').toISOString(), []);
  const {
    data: currentGoals,
    fetchMore: fetchMoreCurrent,
    loading: loadingCurrent,
  } = useGoalParticipationsByHandleQuery({
    variables: { handle: profile.handle, limit: 20, offset: 0, earliestEndDate: today },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: pastGoals,
    fetchMore: fetchMorePast,
    loading: loadingPast,
  } = useGoalParticipationsByHandleQuery({
    variables: { handle: profile.handle, limit: 20, offset: 0, latestEndDate: today },
    notifyOnNetworkStatusChange: true,
  });

  if (!currentGoals && loadingCurrent) return <HalfPageLoadingSpinner />;

  if (currentGoals?.goalParticipations.length === 0 && pastGoals?.goalParticipations.length === 0) {
    return (
      <EmptyState
        text="No goals set!"
        illustrationKey="sunglasses"
        margin={{ '0': '5', '1120': '5 0' }}
        actions={
          isMyProfile
            ? [
                {
                  text: 'Set a goal',
                  onClick: () => router.push(routes.goals),
                },
              ]
            : undefined
        }
      />
    );
  }

  return (
    <div className={classNames('container', styles.container)}>
      {currentGoals && currentGoals.goalParticipations.length > 0 && (
        <div className="mb-10">
          {currentGoals?.goalParticipations.length > 0 &&
            currentGoals?.goalParticipations.map((goalParticipation) => (
              <div key={goalParticipation.id} className="my-2">
                <Link href={routes.goal} as={`/${profile.handle}/goal/${goalParticipation.goal.slug}`}>
                  <a>
                    <GoalMini goal={goalParticipation.goal} progress={goalParticipation.progress} />
                  </a>
                </Link>
              </div>
            ))}
          <Waypoint
            topOffset="2000px"
            onEnter={() => {
              if (!currentGoals || loadingCurrent) return;
              fetchMoreCurrent({
                variables: {
                  offset: currentGoals.goalParticipations.length,
                  limit: 20,
                  earliestEndDate: today,
                },
              });
            }}
          />
        </div>
      )}

      {pastGoals && pastGoals.goalParticipations.length > 0 && (
        <div>
          <h2 className="sectionHeader mb-8">Past</h2>
          {pastGoals?.goalParticipations.map((goalParticipation) => (
            <div key={goalParticipation.id} className="my-2">
              <Link href={routes.goal} as={`/${profile.handle}/goal/${goalParticipation.goal.slug}`}>
                <a>
                  <GoalMini goal={goalParticipation.goal} progress={goalParticipation.progress} />
                </a>
              </Link>
            </div>
          ))}
          <Waypoint
            onEnter={() => {
              if (!pastGoals || loadingPast) return;
              fetchMorePast({
                variables: {
                  offset: pastGoals.goalParticipations.length,
                  limit: 20,
                  latestEndDate: today,
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
