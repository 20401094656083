import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { Tab, TabAlign, TabBar, TabContent } from '../../generic/TabBar';

type TabConfig = {
  name: string;
  href: string;
  as: string;
  component: () => ReactNode;
  preventScroll?: boolean;
};

type Props = {
  tabs: TabConfig[];
  align?: TabAlign;
  sticky?: boolean;
  actionButton?: ReactNode;
};

export const TabNav = ({ align, tabs, sticky, actionButton }: Props) => {
  const router = useRouter();
  const contentRef = useRef<HTMLDivElement>(null);
  const currentAs = router.asPath.split('?')[0] || '';

  useEffect(() => {
    const preventScroll = tabs.find((tab) => tab.as === currentAs)?.preventScroll;
    if (sticky && !preventScroll) {
      const distanceTop = contentRef.current?.getBoundingClientRect().top;
      if (distanceTop && distanceTop < 150) {
        contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentAs, tabs]);

  const component = useMemo(() => {
    const tab = tabs.find((tab) => tab.as === currentAs);
    if (!tab) return <></>; // TODO: Show not found error here
    return tab?.component();
  }, [currentAs, tabs]);

  return (
    <>
      <TabBar align={align} sticky={sticky} actionButton={actionButton}>
        {tabs.map((tab) => {
          const isActive = tab.as === currentAs;
          return (
            <Tab key={tab.name} href={tab.href} as={tab.as} isActive={isActive}>
              {tab.name}
            </Tab>
          );
        })}
      </TabBar>
      <TabContent ref={contentRef}>{component}</TabContent>
    </>
  );
};
