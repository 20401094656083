import classnames from 'classnames';
import { ErrorMessage } from 'components/basics/Error/ErrorMessage';
import { FadeLoader, HalfPageLoadingSpinner } from 'components/basics/LoadingSpinner';
import { BookReview } from 'components/book/review/Review';
import { ActivityReactionBar } from 'containers/Activity/ActivityReactionBar';
import { ReviewShare } from 'containers/share/ReviewShare';
import ShareContentButton from 'containers/share/ShareContentButton';
import { ProfilePartsFragment, useGetUserReviewsQuery } from 'generated/graphql';
import { Waypoint } from 'react-waypoint';
import { EmptyState } from 'ui/generic';
import { getSharableReviewImageUrl } from 'utils/shareMedia';
import styles from './ReviewsTab.module.scss';

type Props = {
  profile: ProfilePartsFragment;
};

export const ReviewsTab = ({ profile }: Props): JSX.Element => {
  const { data, loading, error, fetchMore } = useGetUserReviewsQuery({
    variables: { profileId: profile.id, limit: 20, offset: 0 },
    notifyOnNetworkStatusChange: true,
  });

  if (error) return <ErrorMessage />;
  if (!data && loading) return <HalfPageLoadingSpinner />;

  if (data?.getUserReviews.length === 0) {
    return (
      <EmptyState
        text="No reviews yet. Bummer!"
        illustrationKey="cool-man"
        margin={{ '0': '5', '1120': '5 0' }}
      />
    );
  }

  return (
    <div className={classnames('container', styles.container)}>
      {data?.getUserReviews.map(
        (activity) =>
          activity.data &&
          activity.data.book &&
          activity.data.profile && (
            <div key={activity.data.id} className="mb-10">
              <BookReview
                book={activity.data.book}
                review={activity.data}
                profile={activity.data.profile}
                showBookCover
                linkBookCover
              />
              <div className="mt-4">
                <ActivityReactionBar
                  shareButton={
                    <ShareContentButton
                      reactionBarButton
                      shareImageUrl={(format) =>
                        activity.data && getSharableReviewImageUrl(activity.data.id, format)
                      }
                      renderImagePreview={
                        <ReviewShare
                          book={activity.data.book}
                          review={activity.data}
                          profile={activity.data.profile}
                        />
                      }
                      shareInfo={{
                        path: `/activity/${activity.id}`,
                      }}
                    />
                  }
                  activity={activity}
                />
              </div>
            </div>
          )
      )}
      <Waypoint
        onEnter={() => {
          if (!data || loading) return;
          fetchMore({
            variables: {
              offset: data.getUserReviews.length,
              limit: 20,
            },
          });
        }}
      />
      <FadeLoader loading={loading} />
    </div>
  );
};
