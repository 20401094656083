import ErrorPage from 'pages/_error';
import React from 'react';
import { routes } from 'utils/routes';

export const Profile404 = (): JSX.Element => {
  return (
    <ErrorPage
      statusCode={404}
      hasGetInitialPropsRun={false}
      err={null}
      message="Oops! User profile does not exist..."
      action={{ href: routes.landing, as: '/', title: 'Back to the front page' }}
    />
  );
};
