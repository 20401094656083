import BookListByReadingStatus from 'containers/ProfilePage/BookListByReadingStatus';
import ProfileShelves from 'containers/ProfilePage/ProfileShelves';
import { ProfilePartsFragment, ReadingStatus } from 'generated/graphql';
import { EmptyState } from 'ui/generic';
import { useProfileBookCounts } from './useProfileBookCounts';

type BooksTabProps = {
  profile: ProfilePartsFragment;
  isMyProfile: boolean;
};
export const BooksTab = ({ profile, isMyProfile }: BooksTabProps) => {
  const { counts } = useProfileBookCounts({ profileId: profile.id });

  return (
    <>
      {counts?.contentCount !== undefined && counts?.contentCount === 0 && !isMyProfile ? (
        <EmptyState
          text={`No books in ${profile.name}’s library to show`}
          illustrationKey="sad-person"
          margin={{ '0': '5', '1120': '5 0' }}
        />
      ) : (
        <div className="container">
          <BookListByReadingStatus
            status={ReadingStatus.IsReading}
            owner={profile}
            isMyProfile={isMyProfile}
            count={counts?.isReadingCount}
          />
          <BookListByReadingStatus
            status={ReadingStatus.WantsToRead}
            owner={profile}
            isMyProfile={isMyProfile}
            count={counts?.wantsToReadCount}
          />
          <BookListByReadingStatus
            status={ReadingStatus.Finished}
            owner={profile}
            isMyProfile={isMyProfile}
            count={counts?.finishedCount}
          />
          <ProfileShelves handle={profile.handle} isMyProfile={isMyProfile} profileId={profile.id} />
        </div>
      )}
    </>
  );
};
