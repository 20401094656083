import { ProfilePartsFragment, useRecentBooksByProfileIdQuery } from 'generated/graphql';
import { ShareFormat } from 'hooks/useGetShareFormat';
import { ProfileShare } from './ProfileShare';
type Props = {
  profile: ProfilePartsFragment;
  shareFormat?: ShareFormat;
};

const ShareBookContainer = ({ profile, shareFormat }: Props): JSX.Element => {
  const { data } = useRecentBooksByProfileIdQuery({
    variables: {
      limit: 10,
      profileId: profile.id,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <ProfileShare shareFormat={shareFormat} profile={profile} books={data?.recentBooksByProfileId || []} />
  );
};

export default ShareBookContainer;
