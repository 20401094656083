import { MobileTopBarButtonSlot } from 'components/navigation/MobileTopBar';
import ClubTab from 'containers/Clubs/ClubTab';
import { ProfileHeader } from 'containers/ProfilePage/ProfileHeader';
import ShareContentButton from 'containers/share/ShareContentButton';
import Timeline from 'containers/Timeline';
import { DiscoveryActorType, ProfilePartsFragment } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useDeviceDetectContext } from 'hooks/useDeviceDetect';
import { DiscoveryContextProvider } from 'hooks/useDiscoveryContext';
import { TabNav } from 'ui/specific/layout/TabNav';
import { getSharableProfileImageUrl } from 'utils/shareMedia';
import ProfileShareContainer from '../share/ProfileShareContainer';
import { BooksTab } from './BooksTab';
import { GoalsTab } from './GoalsTab';
import { ProfilePageMetadata } from './ProfilePageMetadata';
import { ReviewsTab } from './ReviewsTab';

type Props = {
  profile: ProfilePartsFragment;
};

const ProfilePage = ({ profile }: Props): JSX.Element => {
  const { profile: loggedProfile } = useAuthContext();
  const { isShareable } = useDeviceDetectContext();
  const isMyProfile = loggedProfile?.id === profile?.id;
  return (
    <DiscoveryContextProvider
      mediatorHandle={profile.handle}
      mediatorId={profile.id}
      mediatorType={DiscoveryActorType.Profile}
    >
      <div className="mb-20" key={profile.id}>
        <ProfilePageMetadata profile={profile} />
        <ProfileHeader profile={profile} key={profile.handle} />
        <TabNav
          tabs={[
            {
              name: 'Books',
              href: '/[handle]',
              as: `/${profile.handle}`,
              component: () => <BooksTab profile={profile} isMyProfile={isMyProfile} />,
            },
            {
              name: 'Reviews',
              href: '/[handle]/[profileTab]',
              as: `/${profile.handle}/reviews`,
              component: () => <ReviewsTab profile={profile} />,
            },
            {
              name: 'Activity',
              href: '/[handle]/[profileTab]',
              as: `/${profile.handle}/activity`,
              component: () => (
                <Timeline
                  profileId={profile.id}
                  emptyStateConfig={{
                    text: `${profile.name} has done \n NOTHING so far...`,
                    illustrationKey: 'people-talking',
                    margin: { '0': '5', '1120': '5 0' },
                  }}
                  withPadding
                />
              ),
            },
            {
              name: 'Clubs',
              href: '/[handle]/[profileTab]',
              as: `/${profile.handle}/clubs`,
              component: () => (
                <ClubTab profileId={profile.id} profileName={profile.name} isMyProfile={isMyProfile} />
              ),
            },
            {
              name: 'Goals',
              href: '/[handle]/[profileTab]',
              as: `/${profile.handle}/goals`,
              component: () => <GoalsTab profile={profile} isMyProfile={isMyProfile} />,
            },
          ]}
          sticky
        />
      </div>
      {isShareable && (
        <MobileTopBarButtonSlot>
          <ShareContentButton
            shareImageUrl={(format) => getSharableProfileImageUrl(profile.handle, format)}
            renderImagePreview={<ProfileShareContainer profile={profile} />}
            shareInfo={{
              path: `/${profile.handle}`,
            }}
          />
        </MobileTopBarButtonSlot>
      )}
    </DiscoveryContextProvider>
  );
};

export default ProfilePage;
