import classNames from 'classnames';
import FollowProfileButton from 'containers/FollowProfileButton';
import ProfileFollowersModal from 'containers/ProfilePage/FollowersModal/ProfileFollowersModal';
import {
  ProfilePartsFragment,
  useGetProfileByHandleQuery,
  useGetProfileByIdQuery,
  useGetProfileFollowCountsQuery,
  useIsLibrarianQuery,
} from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import { useIsSupporter } from 'hooks/useIsSupporter';
import useMixpanel from 'hooks/useMixpanel';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Avatar, Button, ButtonSimple, LinkSimple, LinkText, Tooltip } from 'ui/generic';
import { LibrarianDisplayIcon, PatronDisplayIcon } from 'ui/icons';
import { createEnumParam, useQueryParam } from 'use-query-params';
import { QS } from 'utils/queryStringParams';
import { routes } from 'utils/routes';
import styles from './ProfileHeader.module.scss';

const { publicRuntimeConfig } = getConfig();

type Props = {
  profile: ProfilePartsFragment;
};

const followTabs = createEnumParam(['followers', 'following']);

export const ProfileHeader = ({ profile }: Props): JSX.Element | null => {
  const mixpanel = useMixpanel();
  const router = useRouter();
  const { isSignedIn, profile: loggedInProfile } = useAuthContext();
  const { setSettingsModal } = useGlobalModalContext();
  const [activeFollowTab, setActiveFollowTab] = useQueryParam(QS.followModal, followTabs);

  const { data } = useGetProfileByHandleQuery({ variables: { handle: profile.handle } });
  const isMyProfile = loggedInProfile && loggedInProfile.handle === profile.handle;
  const profileData = data?.profileByHandle || profile;
  const { data: countData } = useGetProfileFollowCountsQuery({ variables: { handle: profile.handle } });
  const { data: invitedBy } = useGetProfileByIdQuery({
    variables: { id: data?.profileByHandle?.invitedByProfileId as string },
    skip: !data?.profileByHandle?.invitedByProfileId,
  });
  const { data: isLibrarian } = useIsLibrarianQuery({ variables: { profileId: profile.id } });

  const isSupporter = useIsSupporter({ profileId: profile.id });

  useEffect(() => {
    if (profileData && isMyProfile && countData && countData.profileByHandle) {
      mixpanel.setProfileProperties(profileData.handle, {
        follower: countData.profileByHandle.followerCount,
        following: countData.profileByHandle.followingCount,
      });
    }
  }, [countData, isMyProfile, profileData]);

  const followingCount = countData?.profileByHandle?.followingCount || null;
  const followerCount = countData?.profileByHandle?.followerCount || null;

  const multiBadge = isLibrarian?.isLibrarian && isSupporter;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.bar}>
          <div className={styles.left}>
            <div className={styles.handle}>@{profileData.handle}</div>
            <div className={styles.follow}>
              {followingCount && (
                <ButtonSimple variant="accent" onClick={() => setActiveFollowTab('following')}>
                  <strong>{followingCount}</strong>&nbsp;Following
                </ButtonSimple>
              )}
              {followerCount && (
                <ButtonSimple variant="accent" onClick={() => setActiveFollowTab('followers')}>
                  <strong>{followerCount}</strong>&nbsp;Followers
                </ButtonSimple>
              )}
            </div>
          </div>

          <div className="ml-5">
            {!isSignedIn ? (
              <Tooltip
                enableOnNonHoverDevices
                text="Sign up to use"
                render={(ref) => (
                  <Link href={routes.register}>
                    <a>
                      <Button variant="focus" ref={ref}>
                        Follow
                      </Button>
                    </a>
                  </Link>
                )}
              />
            ) : (
              publicRuntimeConfig.streamToggle &&
              isSignedIn &&
              !isMyProfile && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <FollowProfileButton variant="focus" profile={profileData} />
                </div>
              )
            )}
          </div>
        </div>
        <div className={styles.inner}>
          <div className={styles.avatar}>
            <Avatar
              profile={profileData}
              width={130}
              assetSize="200"
              disableLazyLoading
              avatarSticker={
                <div
                  className={classNames(styles.stickerContainer, {
                    [styles.multiBadge]: multiBadge,
                  })}
                >
                  {isLibrarian?.isLibrarian && (
                    <Tooltip
                      enableOnNonHoverDevices
                      text="Literal Librarian"
                      positioningStrategy="fixed"
                      render={(ref) => (
                        <div ref={ref} className={classNames(styles.sticker, styles.librarianSticker)}>
                          <LibrarianDisplayIcon />
                        </div>
                      )}
                    />
                  )}

                  {isSupporter && (
                    <Tooltip
                      enableOnNonHoverDevices
                      text="Patron"
                      positioningStrategy="fixed"
                      render={(ref) => (
                        <Link href={routes.patrons}>
                          <a ref={ref} className={classNames(styles.sticker, styles.supporterSticker)}>
                            <PatronDisplayIcon />
                          </a>
                        </Link>
                      )}
                    />
                  )}
                </div>
              }
            />
          </div>
          <h1 className={classNames('displayHeader', styles.name)}>{profileData.name}</h1>
          {profileData.bio && (
            <p>
              <LinkText accent={true}>{profileData.bio}</LinkText>
            </p>
          )}
          {invitedBy?.profileById.handle && (
            <p className="mt-2 textDiscreet">
              Invited by{' '}
              <LinkSimple href={`/${invitedBy.profileById.handle}`} variant="faded">
                @{invitedBy.profileById.handle}
              </LinkSimple>
            </p>
          )}
          <div className={styles.controls}>
            {isMyProfile && (
              <Button variant="secondary" onClick={() => setSettingsModal(true)}>
                Settings
              </Button>
            )}
            {!isMyProfile && (
              <Button
                variant="secondary"
                onClick={() => router.push(routes.chat, `${routes.chat}?${QS.startChat}=${profileData.id}`)}
              >
                Chat
              </Button>
            )}
          </div>
        </div>
      </div>

      {activeFollowTab && (
        <ProfileFollowersModal
          activeTab={activeFollowTab}
          setActiveTab={(val) => setActiveFollowTab(val, 'replaceIn')}
          profileId={profileData.id}
          name={profileData.name}
        />
      )}
    </>
  );
};
