import LoadingSpinner from 'components/basics/LoadingSpinner';
import { ListHeader } from 'components/book/ListHeader';
import BookItem from 'containers/BookItem';
import BookItemLarge from 'containers/BookItemLarge';
import {
  ProfilePartsFragment,
  ReadingStatus,
  useBooksByReadingStateAndProfileQuery,
} from 'generated/graphql';
import Link from 'next/link';
import { Button, EmptyState } from 'ui/generic';
import { List, ListItem, ListItemLarge } from 'ui/specific/layout/List';
import { statesData } from 'utils/readingStates';
import styles from './BookListByReadingStatus.module.scss';

type Props = {
  status: Exclude<ReadingStatus, ReadingStatus.None>;
  owner: ProfilePartsFragment;
  isMyProfile?: boolean;
  count?: number;
};

const BookListByReadingStatus = ({ status, owner, isMyProfile, count }: Props): JSX.Element | null => {
  const stateInfo = statesData[status];

  const { data, loading } = useBooksByReadingStateAndProfileQuery({
    variables: {
      profileId: owner.id,
      readingStatus: status,
      limit: 12,
      offset: 0,
    },
  });

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '408px',
        }}
      >
        <LoadingSpinner />
      </div>
    );

  const books = data?.booksByReadingStateAndProfile;

  if (books?.length === 0 && !isMyProfile) {
    return null;
  }

  return (
    <div>
      <ListHeader
        title={stateInfo.title}
        listSlug={stateInfo.slug}
        booksCount={count || 0}
        ownerHandle={owner.handle}
        hideViewMore={count && count < 13 ? true : undefined}
      />
      {books?.length === 0 && <EmptyState text="No books added yet" />}
      {status === ReadingStatus.IsReading && books && books.length > 0 && (
        <List>
          {books?.map((book) => (
            <ListItemLarge key={book.id}>
              <BookItemLarge
                book={book}
                href={`/[handle]/book/[bookSlug]`}
                as={`/${owner.handle}/book/${book.slug}`}
                hideAvatar={true}
                profileId={owner.id}
                optionsPositioningStrategy="fixed"
              />
            </ListItemLarge>
          ))}
        </List>
      )}
      {status !== ReadingStatus.IsReading && books && books.length > 0 && (
        <List>
          {books.map((book) => (
            <ListItem key={book.id}>
              <BookItem
                book={book}
                href={`/[handle]/book/[bookSlug]`}
                as={`/${owner.handle}/book/${book.slug}`}
                hideAvatar={true}
                profileId={owner.id}
                optionsPositioningStrategy="fixed"
              />
            </ListItem>
          ))}

          {count && count > 12 && (
            <ListItem>
              <Link href={`/[handle]/${stateInfo.slug}`} as={`/${owner.handle}/${stateInfo.slug}`}>
                <a className={styles.viewAll}>
                  <Button mini variant="tertiary">
                    View all
                  </Button>
                </a>
              </Link>
            </ListItem>
          )}
        </List>
      )}
    </div>
  );
};

export default BookListByReadingStatus;
