import { useBookCountsByProfileIdQuery } from 'generated/graphql';
import { useMemo } from 'react';

type Props = {
  profileId: string;
  skip?: boolean;
};

type Counts = {
  finishedCount: number;
  isReadingCount: number;
  shelfCount: number;
  wantsToReadCount: number;
  contentCount: number;
};

type State = {
  loading: boolean;
  counts?: Counts;
};

/**
 * Hook which hides away the complexity of counting books
 */
export function useProfileBookCounts({ profileId, skip = false }: Props): State {
  const { data: countsData, loading } = useBookCountsByProfileIdQuery({
    variables: { profileId },
    skip,
  });

  const counts = useMemo(() => {
    if (!countsData?.bookCountsByProfileId) return undefined;

    const finishedCount = countsData.bookCountsByProfileId.finishedCount;
    const isReadingCount = countsData.bookCountsByProfileId.isReadingCount;
    const shelfCount = countsData.bookCountsByProfileId.shelfCount;
    const wantsToReadCount = countsData.bookCountsByProfileId.wantsToReadCount;
    const contentCount: number = countsData.bookCountsByProfileId
      ? (finishedCount || 0) + (isReadingCount || 0) + (shelfCount || 0) + (wantsToReadCount || 0)
      : 0;

    return {
      finishedCount,
      isReadingCount,
      shelfCount,
      wantsToReadCount,
      contentCount,
    };
  }, [countsData]);

  return {
    loading,
    counts,
  };
}
