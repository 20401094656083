import { useRouter } from 'next/router';
import { Tab, TabBar } from 'ui/generic/TabBar';
import Modal from 'ui/specific/modal/Modal';
import { canGoBack } from 'utils/queryStringParams';
import FollowersTab from './FollowersTab';
import FollowingTab from './FollowingTab';
import styles from './ProfileFollowersModal.module.scss';

type Props = {
  profileId: string;
  name: string;
  activeTab: 'followers' | 'following';
  setActiveTab: (tab?: 'followers' | 'following') => void;
};

const ProfileFollowersModal = ({ name, profileId, activeTab, setActiveTab }: Props): JSX.Element | null => {
  const router = useRouter();
  return (
    <Modal close={() => (canGoBack() ? router.back() : setActiveTab())}>
      <div className={styles.top}>
        <h2 className="displayHeader flex align-start">{name}'s network</h2>
        <TabBar align="left">
          <Tab onClick={() => setActiveTab('following')} isActive={activeTab === 'following'}>
            Following
          </Tab>
          <Tab onClick={() => setActiveTab('followers')} isActive={activeTab === 'followers'}>
            Followers
          </Tab>
        </TabBar>
      </div>

      {activeTab === 'following' && <FollowingTab profileId={profileId} />}
      {activeTab === 'followers' && <FollowersTab profileId={profileId} />}
    </Modal>
  );
};

export default ProfileFollowersModal;
