import { ErrorMessage } from 'components/basics/Error/ErrorMessage';
import { HalfPageLoadingSpinner } from 'components/basics/LoadingSpinner';
import ShelvesGrid from 'components/shelf/Grid';
import ShelfItem from 'components/shelf/Item';
import CreateShelfModal from 'containers/CreateShelfModal';
import { useGetBooksOnShelfCountsQuery, useGetShelvesByProfileIdQuery } from 'generated/graphql';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Button, EmptyState, SectionHeader } from 'ui/generic';

type Props = {
  handle: string;
  profileId: string;
  isMyProfile: boolean;
};

const ProfileShelves = ({ handle, profileId, isMyProfile }: Props): JSX.Element => {
  const { smScreen } = useScreenSize();
  const [isCreateShelfModalOpen, setCreateShelfModalOpen] = useState(false);

  const { data, loading, error, fetchMore } = useGetShelvesByProfileIdQuery({
    variables: { profileId: profileId, limit: 20, offset: 0 },
  });

  const { data: booksOnShelvesCounts } = useGetBooksOnShelfCountsQuery({
    variables: { profileId },
  });

  if (loading) return <HalfPageLoadingSpinner />;
  if (error) return <ErrorMessage />;

  const getCountByShelfId = (shelfId: string) => {
    if (booksOnShelvesCounts) {
      const record = booksOnShelvesCounts.getBooksOnShelfCounts.find(
        (booksOnShelfCount) => booksOnShelfCount.shelfId === shelfId
      );
      return record?.bookCount;
    }
  };

  return (
    <div>
      {(isMyProfile || (data?.getShelvesByProfileId && data?.getShelvesByProfileId.length > 0)) && (
        <SectionHeader
          title="Shelves"
          right={
            isMyProfile ? (
              <Button variant="secondary" mini={smScreen} onClick={() => setCreateShelfModalOpen(true)}>
                Create shelf
              </Button>
            ) : null
          }
        />
      )}
      <CreateShelfModal isOpen={isCreateShelfModalOpen} setOpen={setCreateShelfModalOpen} pushToShelfPage />

      {isMyProfile && data?.getShelvesByProfileId && data?.getShelvesByProfileId.length === 0 && (
        <EmptyState text="No shelf created yet" />
      )}
      <ShelvesGrid>
        {data?.getShelvesByProfileId.map((shelf) => (
          <ShelfItem
            key={shelf.id}
            shelf={shelf}
            ownerHandle={handle}
            booksCount={getCountByShelfId(shelf.id)}
          />
        ))}
        <Waypoint
          onEnter={() => {
            if (!data || loading) return;
            fetchMore({
              variables: {
                offset: data?.getShelvesByProfileId.length,
                limit: 20,
              },
            });
          }}
        />
      </ShelvesGrid>
    </div>
  );
};

export default ProfileShelves;
