import LoadingSpinner from 'components/basics/LoadingSpinner';
import FollowProfileButton from 'containers/FollowProfileButton';
import { UserInlineContainer } from 'containers/inline/UserInlineContainer';
import { useGetFollowersQuery } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useCallback } from 'react';
import { Waypoint } from 'react-waypoint';
import { ModalNegativeMargin } from 'ui/specific/modal/ModalNegativeMargin';

type Props = {
  profileId: string;
};

const FollowersTab = ({ profileId }: Props): JSX.Element => {
  const { profile: loggedInProfile } = useAuthContext();
  const { data, loading, fetchMore } = useGetFollowersQuery({
    variables: { profileId, limit: 20, offset: 0 },
  });

  const loadMore = useCallback(async () => {
    if (!data || loading) return;

    fetchMore({
      variables: {
        offset: data?.followers?.length,
        limit: 20,
        profileId,
      },
    });
  }, [loading, data]);

  if (loading) return <LoadingSpinner />;

  return (
    <div>
      <ModalNegativeMargin>
        {data
          ? data.followers.map((follower) => (
              <UserInlineContainer
                border
                link
                key={follower.id}
                profile={follower}
                right={
                  loggedInProfile &&
                  loggedInProfile.handle !== follower.handle && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <FollowProfileButton profile={follower} mini />
                    </div>
                  )
                }
              />
            ))
          : null}
        <Waypoint onEnter={loadMore} />
      </ModalNegativeMargin>
    </div>
  );
};

export default FollowersTab;
